
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPayment from "@/mixins/mixinPayment";
  import global from "@/vuex/payment/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";

  import CommonText from "@/components/common/Text.vue";
  import CommonAllowance from "@/components/common/Allowance.vue";

  @Component({
    components: {
      CommonText,
      CommonAllowance,
    },
  })
  export default class Row extends Mixins(mixinPayment) {
    @Prop() each_payment;

    change_each_payment_allowance(id: number, val) {
      Vue.set(this.tmp_each_payment.each_payment_allowances, id, val);
    }
    change_each_payment_deduction(id: number, val) {
      Vue.set(this.tmp_each_payment.each_payment_deductions, id, val);
    }
    change_each_payment_other_allowance(id: number, val) {
      Vue.set(this.tmp_each_payment.each_payment_other_allowances, id, val);
    }

    get tmp_each_payment() {
      return this.m.each_payment[this.each_payment.employee_id];
    }
    set tmp_each_payment(val) {
      this.m.setEachPayment({
        employee_id: this.each_payment.employee_id,
        each_payment_object: val,
      });
    }

    get is_edit() {
      return this.m.edit_each_payments_flag;
    }
  }
