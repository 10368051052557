
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import PaymentMonthchanger from "@/components/payment/Monthchanger.vue";
import PaymentMain from "@/components/payment/Main.vue";

@Component({
  components: {
    PaymentMonthchanger,
    PaymentMain,
  }
})
export default class Payment extends Mixins(utilMixins) {

}
