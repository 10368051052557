
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPayment from "@/mixins/mixinPayment";
  import global from "@/vuex/payment/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";
  import CommonProgress from "@/components/common/Progress.vue";
  import PaymentTableControl from "@/components/payment/table/Control.vue";
  import PaymentTableTable from "@/components/payment/table/Table.vue";
  import PaymentPagerFoot from "@/components/payment/pager/Foot.vue";
  import PaymentDialogExcelMain from "@/components/payment/dialog/excel/Main.vue";
  import PaymentDialogStdincomeMain from "@/components/payment/dialog/stdincome/Main.vue";

  @Component({
    components: {
      CommonProgress,
      PaymentTableControl,
      PaymentTableTable,
      PaymentPagerFoot,
      PaymentDialogExcelMain,
      PaymentDialogStdincomeMain,
    },
  })
  export default class Main extends Mixins(mixinPayment) {}
