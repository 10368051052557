
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinPayment from '@/mixins/mixinPayment';
import monthly from "@/vuex/payment/monthly";
import daily from "@/vuex/payment/daily";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/payment/manager";
import realtime from "@/vuex/payment/realtime";
import UtilPopup from "@/components/util/Popup.vue";
import CommonProgress from "@/components/common/Progress.vue";
import CommonPager from "@/components/common/Pager.vue";
import CommonButton from "@/components/common/Button.vue";
import PaymentDialogStdincomePagerHead from "@/components/payment/dialog/stdincome/pager/Head.vue";
import PaymentDialogStdincomeTable from "@/components/payment/dialog/stdincome/Table.vue";
import PaymentDialogStdincomePagerFoot from "@/components/payment/dialog/stdincome/pager/Foot.vue";

@Component({
  components: {
    UtilPopup,
    CommonProgress,
    CommonPager,
    CommonButton,
    PaymentDialogStdincomePagerHead,
    PaymentDialogStdincomeTable,
    PaymentDialogStdincomePagerFoot,
  }
})
export default class Main extends Mixins(mixinPayment) {
  get is_display() {
    return this.m.is_open_update_stdincome_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenUpdateStdincomeDialog(val);
  }
}
