
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import global from "@/vuex/payment/global";
import mixinPayment from '@/mixins/mixinPayment';
import * as util from '@/util';
import UtilPopup from "@/components/util/Popup.vue";
import PaymentDialogExcelControlbutton from "@/components/payment/dialog/excel/Controlbutton.vue";
import CommonBigbutton from "@/components/common/Bigbutton.vue";

@Component({
  components: {
    UtilPopup,
    PaymentDialogExcelControlbutton,
    CommonBigbutton,
  }
})
export default class Main extends Mixins(mixinPayment) {
  excel_file = null;

  get refs():any {
    return this.$refs;
  }

  get is_display() {
    return this.m.is_open_payment_excel_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenPaymentExcelDialog(val);
  }

  get yearmonthstr() {
    return util.formatDate(util.date2obj(this.date+'-01'), 'yyyy年mm月');
  }

  select_file(e) {
    e.preventDefault();
    let files = e.target.files;
    this.excel_file = files[0];
    if (this.excel_file) {
      var name = this.excel_file.name.split('.');
      name = name[name.length - 1];
      if (name != 'xlsx') {
        if (name == 'xls') {
          dialog.openErrorAlertDialog({msg: '古いExcel(.xlsファイル)は使用できません。', detail: 'フォームをダウンロードして使用してください。'})
        } else {
          dialog.openErrorAlertDialog({msg: 'Excelファイルのみアップロード可能です。', detail: '.xlsxファイルのみ使用可能です。'})
        }
        this.excel_file = null;
        this.refs.payment_ref.reset_file();
      }
    }
  }

  async update_payment_excel() {
    await this.m.updateEachPaymentsExcel({excel: this.excel_file, branch_id: this.viewing_branch, yearmonth: this.date});
    this.excel_file = null;
    this.refs.payment_ref.reset_file();
  }
}
