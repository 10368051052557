
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPayment from "@/mixins/mixinPayment";
  import global from "@/vuex/payment/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";
  import CommonProgress from "@/components/common/Progress.vue";
  import PaymentTableMain from "@/components/payment/table/Main.vue";

  @Component({
    components: {
      CommonProgress,
      PaymentTableMain,
    },
  })
  export default class Main extends Mixins(mixinPayment) {
    get monthstr() {
      return util.formatDate(this.dateobj, "yyyy年mm月");
    }

    prev_month() {
      const dateobj = util.date2obj(`${this.date}-01`);
      dateobj.setDate(dateobj.getMonth() - 1);
      this.date = util.formatDate(dateobj, "yyyy-mm");
    }
    next_month() {
      const dateobj = util.date2obj(`${this.date}-01`);
      dateobj.setDate(dateobj.getMonth() + 1);
      this.date = util.formatDate(dateobj, "yyyy-mm");
    }
  }
