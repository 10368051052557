
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinPayment from '@/mixins/mixinPayment';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/shift/manager";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Errorcheck extends Mixins(mixinPayment) {
  payment_excel() {
    this.m.payment_excel({yearmonth: this.date});
  }
}
