
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPayment from "@/mixins/mixinPayment";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";
  import PaymentTableBranchselector from "@/components/payment/table/Branchselector.vue";
  import PaymentPagerHead from "@/components/payment/pager/Head.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      PaymentTableBranchselector,
      PaymentPagerHead,
      CommonButton,
    },
  })
  export default class Control extends Mixins(mixinPayment) {
    gensen_pay() {
      this.m.gensen_pay({ yearmonth: this.date });
    }
    payment_csv() {
      this.m.payment_csv({ branch_id: this.viewing_branch, yearmonth: this.date });
    }

    async zengin() {
      await this.m.zengin({ branch_id: this.viewing_branch, yearmonth: this.date });
    }
    async zengin_zip() {
      await this.m.zengin_zip({ branch_id: this.viewing_branch, yearmonth: this.date });
    }

    update_each_payments() {
      this.m.updateEachPayments({
        branch_id: this.viewing_branch,
        yearmonth: this.date,
        employee_hash: this.employee_hash,
        saved_payment_allowances: this.saved_payment_allowances,
        saved_payment_deductions: this.saved_payment_deductions,
        saved_payment_other_allowances: this.saved_payment_other_allowances,
      });
    }

    lock() {
      const branch_name =
        this.viewing_branch == 0 ? "全店舗" : this.branch_hash[this.viewing_branch];
      this.m.lock({
        yearmonth: this.date,
        branch_id: this.viewing_branch,
        branch_name,
        is_nencho: this.nencho !== null,
      });
    }
    unlock() {
      const branch_name =
        this.viewing_branch == 0 ? "全店舗" : this.branch_hash[this.viewing_branch];
      this.m.unlock({
        yearmonth: this.date,
        branch_id: this.viewing_branch,
        branch_name,
      });
    }
    recalc() {
      const branch_name =
        this.viewing_branch == 0 ? "全店舗" : this.branch_hash[this.viewing_branch];
      this.m.recalc({
        yearmonth: this.date,
        branch_id: this.viewing_branch,
        branch_name,
      });
    }

    edit_each_payments() {
      this.query = {
        is_open_payment_allowance: true,
        is_open_payment_deduction: true,
        is_open_payment_other: true,
      };

      this.each_payments.forEach((each_payment) => {
        if (this.lock_types_hash[this.employee_hash[each_payment.employee_id].branch_id] != 2) {
          this.m.setEachPayment({
            employee_id: each_payment.employee_id,
            each_payment_object: {
              each_payment_allowances: util.deep_copy(each_payment.each_payment_allowances),
              each_payment_deductions: util.deep_copy(each_payment.each_payment_deductions),
              each_payment_other_allowances: util.deep_copy(
                each_payment.each_payment_other_allowances,
              ),

              reg_pay: each_payment.reg_pay,
              hol_pay: each_payment.hol_pay,
              mid_pay: each_payment.mid_pay,
              over_pay: each_payment.over_pay,
              deemover_pay: each_payment.deemover_pay,
              paid_hol_pay: each_payment.paid_hol_pay,
              carfare_pay: each_payment.carfare_pay,

              health_ins: each_payment.health_ins,
              care_ins: each_payment.care_ins,
              pension_ins: each_payment.pension_ins,
              emp_ins: each_payment.emp_ins,
              social_ins_adj: each_payment.social_ins_adj,
              res_tax: each_payment.res_tax,
              income_tax: each_payment.income_tax,
              fixed_tax_reduction: each_payment.is_valid_fixed_tax_reduction
                ? each_payment.fixed_tax_reduction
                : null,

              yearend_adj: each_payment.yearend_adj,
              settlement: each_payment.settlement,

              paid_cash: each_payment.paid_cash,
            },
          });
        }
      });

      this.m.editEachPayments();
    }

    get is_able_lock() {
      if (this.viewing_branch == 0) {
        return this.payment_info.lock_type == 1;
      } else {
        return this.lock_types_hash[this.viewing_branch] == 1;
      }
    }
    get is_able_unlock() {
      if (this.viewing_branch == 0) {
        return this.payment_info.lock_type == 2;
      } else {
        return this.lock_types_hash[this.viewing_branch] == 2;
      }
    }

    get is_able_calc() {
      if (this.viewing_branch == 0) {
        if (this.payment_info.lock_type == 0) {
          return 1; //勤怠ロックがされていない
        } else if (this.payment_info.lock_type == 2) {
          return 2; //給与ロックがされてしまっている
        } else if (this.global.is_each_payment_lock) {
          return 3; //1店舗でも給与ロックがされてしまっている。
        } else {
          return 0;
        }
      } else {
        if (this.lock_types_hash[this.viewing_branch] == 0) {
          return 1; //勤怠ロックがされていない
        } else if (this.lock_types_hash[this.viewing_branch] == 2) {
          return 2; //給与ロックがされてしまっている
        } else if (this.global.is_each_payment_lock) {
          return 3; //1店舗でも給与ロックがされてしまっている。
        } else {
          return 0;
        }
      }
    }
  }
