
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinPayment from '@/mixins/mixinPayment';
import global from "@/vuex/payment/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/payment/manager";

@Component({
  components: {
  }
})
export default class Row extends Mixins(mixinPayment) {
  @Prop() each_stdincome;
}
