
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPayment from "@/mixins/mixinPayment";
  import global from "@/vuex/payment/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";
  import PaymentTableInputrow from "@/components/payment/table/Inputrow.vue";
  import PaymentTableRow from "@/components/payment/table/Row.vue";

  @Component({
    components: {
      PaymentTableInputrow,
      PaymentTableRow,
    },
  })
  export default class Table extends Mixins(mixinPayment) {
    get sum() {
      const sum = {
        reg_pay: 0,
        hol_pay: 0,
        mid_pay: 0,
        over_pay: 0,
        deemover_pay: 0,
        paid_hol_pay: 0,
        allowance_pay: {},
        carfare_pay: 0,
        plus_sum: 0,

        health_ins: 0,
        care_ins: 0,
        pension_ins: 0,
        emp_ins: 0,
        social_ins_adj: 0,
        income_tax: 0,
        fixed_tax_reduction: 0,
        res_tax: 0,
        deduction_pay: {},
        minus_sum: 0,

        yearend_adj: 0,
        settlement: 0,
        other_allowance_pay: {},
        other_sum: 0,

        real_pay: 0,
        paid_cash: 0,
        sum_tax: 0,
      };

      this.saved_payment_allowances.forEach((allowance) => {
        sum.allowance_pay[allowance.id] = 0;
      });
      this.saved_payment_deductions.forEach((deduction) => {
        sum.deduction_pay[deduction.id] = 0;
      });
      this.saved_payment_other_allowances.forEach((other_allowance) => {
        sum.other_allowance_pay[other_allowance.id] = 0;
      });

      this.each_payments.forEach((each_payment) => {
        (sum.reg_pay += each_payment.reg_pay),
          (sum.hol_pay += each_payment.hol_pay),
          (sum.mid_pay += each_payment.mid_pay),
          (sum.over_pay += each_payment.over_pay),
          (sum.deemover_pay += each_payment.deemover_pay),
          (sum.paid_hol_pay += each_payment.paid_hol_pay),
          (sum.carfare_pay += each_payment.carfare_pay),
          (sum.plus_sum += each_payment.plus_sum),
          (sum.health_ins += each_payment.health_ins),
          (sum.care_ins += each_payment.care_ins),
          (sum.pension_ins += each_payment.pension_ins),
          (sum.emp_ins += each_payment.emp_ins),
          (sum.social_ins_adj += each_payment.social_ins_adj),
          (sum.income_tax += each_payment.income_tax),
          (sum.fixed_tax_reduction += each_payment.fixed_tax_reduction),
          (sum.res_tax += each_payment.res_tax),
          (sum.minus_sum += each_payment.minus_sum),
          (sum.yearend_adj += each_payment.yearend_adj),
          (sum.settlement += each_payment.settlement),
          (sum.other_sum += each_payment.other_sum),
          (sum.real_pay += each_payment.real_pay),
          (sum.paid_cash += each_payment.paid_cash),
          (sum.sum_tax += each_payment.sum_tax),
          this.saved_payment_allowances.forEach((allowance) => {
            sum.allowance_pay[allowance.id] += each_payment.each_payment_allowances[allowance.id]
              ? each_payment.each_payment_allowances[allowance.id].money
              : 0;
          });
        this.saved_payment_deductions.forEach((deduction) => {
          sum.deduction_pay[deduction.id] += each_payment.each_payment_deductions[deduction.id]
            ? each_payment.each_payment_deductions[deduction.id].money
            : 0;
        });
        this.saved_payment_other_allowances.forEach((other_allowance) => {
          sum.other_allowance_pay[other_allowance.id] += each_payment.each_payment_other_allowances[
            other_allowance.id
          ]
            ? each_payment.each_payment_other_allowances[other_allowance.id].money
            : 0;
        });
      });

      return sum;
    }
    get allowance_colspan() {
      if (!this.is_open_allowance && !this.m.edit_each_payments_flag) {
        return 1;
      }
      let sum = 7;
      if (this.is_deem_over) {
        sum++;
      }
      sum += this.saved_payment_allowances.length;
      return sum;
    }
    get deduction_colspan() {
      if (!this.is_open_deduction && !this.m.edit_each_payments_flag) {
        return 1;
      }
      let sum = 8;
      if (this.is_fixed_tax_reduction) {
        sum++;
      }
      sum += this.saved_payment_deductions.length;
      return sum;
    }
    get other_colspan() {
      if (!this.is_open_other && !this.m.edit_each_payments_flag) {
        return 1;
      }
      let sum = 3;
      sum += this.saved_payment_other_allowances.length;
      return sum;
    }
    get table_column() {
      return this.allowance_colspan + this.deduction_colspan + this.other_colspan;
    }
  }
