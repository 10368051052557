
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinPayment from '@/mixins/mixinPayment';
import dialog from "@/vuex/dialog";
import global from "@/vuex/payment/global";
import manager from "@/vuex/payment/manager";
import need_manager from "@/vuex/payment/need_manager";
import need_global from "@/vuex/payment/need_global";
import header from "@/vuex/header";
import PaymentDialogStdincomeRow from "@/components/payment/dialog/stdincome/Row.vue";
import PaymentDialogStdincomeFoot from "@/components/payment/dialog/stdincome/Foot.vue";

@Component({
  components: {
    PaymentDialogStdincomeRow,
    PaymentDialogStdincomeFoot,
  }
})
export default class Needtable extends Mixins(mixinPayment) {
}
