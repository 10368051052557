
  import { Component, Vue, Emit, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import utilMixins from "@/mixins";
  import mixinPayment from "@/mixins/mixinPayment";
  import global from "@/vuex/payment/global";
  import CommonSelect from "@/components/common/Select.vue";

  @Component({
    components: {
      CommonSelect,
    },
  })
  export default class Branchselector extends Mixins(mixinPayment) {
    get branch_hash() {
      return this.global.branch_hash;
    }
  }
