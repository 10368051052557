
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinPayment from '@/mixins/mixinPayment';
import global from "@/vuex/payment/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/payment/manager";

@Component({
  components: {
  }
})
export default class Row extends Mixins(mixinPayment) {
  get payment_sum() {
    let payment_sum = 0;
    this.stdincome.forEach(each_stdincome => {
      payment_sum += each_stdincome.payment;
    });
    return payment_sum;
  }
  get carfare_sum() {
    let carfare_sum = 0;
    this.stdincome.forEach(each_stdincome => {
      carfare_sum += each_stdincome.carfare;
    });
    return carfare_sum;
  }
  get sum() {
    return this.payment_sum + this.carfare_sum;
  }
}
